<div class="flex flex-col">
  @if (!isToolSuggestionList) {
    <div
      (mouseenter)="searchHovered = true"
      (mouseleave)="searchHovered = false"
      class="relative mb-8 flex h-10 items-center lg:w-60"
    >
      <input
        type="input"
        class="block h-full w-full border border-[#D1D1D1] px-4 text-sm placeholder-veryTransparentBlack placeholder:font-semibold focus:border-sunflowerGold focus:ring-sunflowerGold"
        placeholder="Search reviews..."
        [(ngModel)]="filterOptions.searchParam"
        (input)="onSearch()"
      />
      @if (searchHovered) {
        <span class="absolute right-5 cursor-pointer text-lg text-mediumGray" (click)="resetSearch()">x</span>
      } @else {
        <img class="absolute right-4 top-3 h-4" src="assets/img/icons/search.png" alt="search" />
      }
    </div>
  }
  <div class="relative flex items-center justify-center gap-4 max-lg:flex-col">
    @if (isToolSuggestionList) {
      <app-filter-segment
        [selectedOption]="filterOptions"
        (sortOptionSelected)="filterOptionSelected($event)"
      ></app-filter-segment>
    } @else {
      <app-sorting-segment
        [ngClass]="{ 'mt-4': isToolSuggestionList }"
        [selectedOption]="filterOptions"
        (sortOptionSelected)="sortOptionSelected($event)"
      ></app-sorting-segment>
    }

    @if (!isToolSuggestionList && !router.url.includes('/dashboard') && authService.getCurrentEmployee().isAdmin) {
      <div class="right-0 flex gap-4 md:absolute">
        <label class="text-sm font-medium text-black">Closed</label>
        <input
          type="checkbox"
          [checked]="filterOptions.filterBy === EToolStatus.CLOSED"
          class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          data-cy="tool-closed-toggle"
          (change)="toggleClosedTools()"
        />
      </div>
    }
  </div>
</div>

@if (isToolSuggestionList) {
  @if (toolSuggestions.length === 0) {
    <div class="mt-8 flex items-center justify-center">
      <span class="text-center text-sm font-semibold text-black">There are currently no suggestions</span>
    </div>
  } @else {
    <div class="mt-4" data-cy="suggestion-tool-list">
      @for (tool of toolSuggestions; track tool; let i = $index) {
        <app-ai-tool-suggestion-card
          [tool]="tool"
          (toolCardRemoved)="onToolSuggestionRemoved($event)"
        ></app-ai-tool-suggestion-card>
      }
      <!--       <div class="mt-6 flex justify-center">
        <pagination-controls (pageChange)="currentSuggestionPage = $event"></pagination-controls>
      </div> -->
    </div>
  }
} @else {
  @if (toolReviews.length === 0) {
    <div data-cy="no-reviews-message" class="mt-8 flex items-center justify-center">
      <span class="text-center text-sm font-semibold text-black">There are currently no reviews</span>
    </div>
  } @else {
    <div data-cy="tool-list">
      @for (
        tool of toolReviews
          | paginate: { itemsPerPage: itemsPerPage, currentPage: currentReviewPage, totalItems: totalCount };
        track tool;
        let i = $index
      ) {
        <app-ai-tool-review-card
          [tool]="tool"
          (toolCardRemoved)="onToolReviewRemoved($event)"
          (onEditTool)="onOpenAiToolDialog($event)"
        ></app-ai-tool-review-card>
      }
    </div>
    <div class="mt-6 flex justify-center">
      <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
    </div>
  }
}
@if (showAiToolEditDialog) {
  <app-ai-tool-dialog
    #aiToolDialogComponent
    [isSuggestionDialog]="false"
    [tool]="selectedTool"
    [editingMode]="true"
    (onCloseDialog)="showAiToolEditDialog = false"
    (onReviewFormSubmit)="updateToolReview($event)"
  ></app-ai-tool-dialog>
}
