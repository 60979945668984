import { Component, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { AiToolService } from 'src/app/services/aiTool.service';
import { IAiTool, IAiToolSuggestion } from 'src/app/models/ai-tool.model';
import { ToastrService } from 'ngx-toastr';
import { AiToolListComponent } from 'src/app/components/ai-tool-list/ai-tool-list.component';

@Component({
  selector: 'app-ai-tools',
  templateUrl: './ai-tools.component.html',
  styleUrl: './ai-tools.component.scss',
})
export class AiToolsComponent {
  @ViewChild('toolReviewListComponent') toolReviewListComponent: AiToolListComponent;
  @ViewChild('toolSuggestionListComponent') toolSuggestionListComponent: AiToolListComponent;

  public showAiToolDialog = false;
  public isSuggestionDialog = false;
  private subscription = new Subscription();

  constructor(
    private aiToolService: AiToolService,
    private toastr: ToastrService,
  ) {}

  public onOpenAiToolDialog(isSuggestionDialog: boolean): void {
    this.isSuggestionDialog = isSuggestionDialog;
    this.showAiToolDialog = true;
  }

  public createNewToolReview(tool: IAiTool): void {
    this.subscription.add(
      this.aiToolService.createToolReview(tool).subscribe({
        next: (res: any) => {
          this.toolReviewListComponent.getAllToolReviews();

          this.showAiToolDialog = false;
          this.toastr.success('Tool review was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }
  public createNewToolSuggestion(tool: IAiToolSuggestion): void {
    this.subscription.add(
      this.aiToolService.createToolSuggestion(tool).subscribe({
        next: (res: any) => {
          this.toolSuggestionListComponent.getAllToolSuggestions();

          this.showAiToolDialog = false;
          this.toastr.success('Tool suggestion was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }
}
