import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin } from 'rxjs';
import { AuthService } from './services/auth.service';
import { HelperService } from './services/helper.service';
import { TransactionService } from './services/transaction.service';
import { TrainingService } from './services/training.service';
import * as Sentry from '@sentry/angular';
import { IEmployee } from './models/employee.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'preyFrontend';
  private subscription = new Subscription();
  public currentUserCoinBalance: number;
  public showSidebar = false;
  public passwordDialog = false;
  public amountOpenRequests: number;
  public employee = {} as IEmployee;

  constructor(
    public router: Router,
    public helperService: HelperService,
    private toastr: ToastrService,
    private transactionService: TransactionService,
    private authService: AuthService,
    private trainingService: TrainingService,
  ) {
    Sentry.setTags({
      userId: this.authService.getCurrentEmployee()?._id || 'unknown',
      application: 'Frontend',
    });
  }

  ngOnInit() {
    this.employee = this.authService.getCurrentEmployee();
    if (!this.employee) {
      this.router.navigateByUrl('/login');
    }
    this.helperService.showSidebar$.subscribe((isVisible) => {
      this.showSidebar = isVisible;
    });
    this.onResize();

    if (this.authService.getCurrentEmployee()) {
      this.getCurrentUserCoins();
      if (this.authService.getCurrentEmployee()?.isAdmin) this.getAmountOpenRequests();
      this.helperService.userCoinsChanged$.subscribe((changed: boolean) => {
        if (changed) {
          this.getCurrentUserCoins();
        }
      });
      this.helperService.getAmountRequestedForAdminBadge().subscribe((changed: boolean) => {
        if (changed) {
          if (this.authService.getCurrentEmployee()?.isAdmin) this.getAmountOpenRequests();
        }
      });
    }

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.getCurrentUserCoins();
        if (this.authService.getCurrentEmployee()?.isAdmin) this.getAmountOpenRequests();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    if (window.innerWidth < 1400) {
      this.helperService.setSidebarVisibility(false);
    } else {
      this.helperService.setSidebarVisibility(true);
    }
  }

  // Use forkJoin to handle both requests concurrently
  private getAmountOpenRequests(): void {
    // Define the observables for both requests
    const request1$ = this.transactionService.getAllTransactionsAmount();
    const request2$ = this.trainingService.getAmountOpenTrainingsRequests();

    // Use forkJoin to handle both requests concurrently
    this.subscription.add(
      forkJoin([request1$, request2$]).subscribe(([res1, res2]) => {
        // Extract the amounts from both responses
        const amount1 = res1.DATA.amountRequested;
        const amount2 = res2.DATA.amountRequested;

        // Sum the amounts
        this.amountOpenRequests = amount1 + amount2;
      }),
    );
  }

  public isMobile(): boolean {
    if (window.innerWidth < 1023) {
      return true;
    } else {
      return false;
    }
  }

  private getCurrentUserCoins(): void {
    this.subscription.add(
      this.transactionService.getMyCoins().subscribe({
        next: (res) => {
          this.currentUserCoinBalance = res.DATA.coinBalance;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }
}
