<div class="relative flex items-center justify-center gap-1 rounded p-[2px] text-white">
  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.filterBy === EToolStatus.ALL,
      'bg-gray-300': selectedOption.filterBy !== EToolStatus.ALL,
    }"
    (click)="selectOption(EToolStatus.ALL)"
    class="sm:w-26 w-24 min-w-fit cursor-pointer rounded px-2 py-1 text-center text-xs transition-all duration-300"
    data-cy="sort-all"
  >
    <span>Alle</span>
  </div>

  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.filterBy === EToolStatus.UNASSIGNED,
      'bg-gray-300': selectedOption.filterBy !== EToolStatus.UNASSIGNED,
    }"
    (click)="selectOption(EToolStatus.UNASSIGNED)"
    class="sm:w-26 w-24 min-w-fit cursor-pointer rounded px-2 py-1 text-center text-xs transition-all duration-300"
    data-cy="sort-unassigned"
  >
    <span>Unassigned</span>
  </div>

  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.filterBy === EToolStatus.ASSIGNED,
      'bg-gray-300': selectedOption.filterBy !== EToolStatus.ASSIGNED,
    }"
    (click)="selectOption(EToolStatus.ASSIGNED)"
    class="sm:w-26 w-24 min-w-fit cursor-pointer rounded px-2 py-1 text-center text-xs transition-all duration-300"
    data-cy="sort-assigned"
  >
    <span>Assigned</span>
  </div>
</div>
