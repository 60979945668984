<div class="flex w-full flex-col items-center justify-center gap-6">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    <div class="flex flex-col gap-3 bg-lightGrayish p-5 lg:gap-5">
      <app-text-content-box [headline]="'Info'" [headerIcon]="'ℹ️'"></app-text-content-box>
      <p class="text-xs lg:text-base">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores sequi fugiat nemo. Itaque quae totam nihil cum
        ea aperiam ab doloremque sed, pariatur ad beatae enim tempora, voluptatibus culpa rerum. Lorem ipsum dolor sit
        amet consectetur adipisicing elit. Maiores sequi fugiat nemo. Itaque quae totam nihil cum ea aperiam ab
        doloremque sed, pariatur ad beatae enim tempora, voluptatibus culpa rerum.
      </p>
    </div>
    <div class="flex max-lg:flex-col lg:gap-4">
      <div class="mb-5 flex flex-1 flex-col bg-lightGrayish p-5 shadow-md" data-cy="feedback-container">
        <div class="mb-4 flex items-center justify-between">
          <app-text-content-box class="mb-4" headline="List of Tools" headerIcon="🤖"></app-text-content-box>
          <button
            data-cy="add-review-button"
            (click)="onOpenAiToolDialog(false)"
            class="flex h-8 w-32 items-center justify-center border border-black bg-almostBlack p-2 text-xs text-sunflowerGold"
          >
            Add Review
          </button>
        </div>
        <app-ai-tool-list [isToolSuggestionList]="false" #toolReviewListComponent></app-ai-tool-list>
      </div>
      <div class="mb-5 flex w-full flex-col bg-lightGrayish p-5 shadow-md lg:w-1/3" data-cy="feedback-container">
        <div class="mb-4 flex items-center justify-between">
          <app-text-content-box headline="To be Tested" headerIcon="🤖"></app-text-content-box>
          <button
            data-cy="add-suggestion-button"
            (click)="onOpenAiToolDialog(true)"
            class="flex h-8 w-32 items-center justify-center border border-black bg-almostBlack p-2 text-xs text-sunflowerGold"
          >
            Add Suggestion
          </button>
        </div>

        <app-ai-tool-list [isToolSuggestionList]="true" #toolSuggestionListComponent></app-ai-tool-list>
      </div>
    </div>
  </div>
</div>
@if (showAiToolDialog) {
  <app-ai-tool-dialog
    #aiToolDialogComponent
    [isSuggestionDialog]="isSuggestionDialog"
    (onCloseDialog)="showAiToolDialog = false"
    (onReviewFormSubmit)="createNewToolReview($event)"
    (onSuggestionFormSubmit)="createNewToolSuggestion($event)"
  ></app-ai-tool-dialog>
}
