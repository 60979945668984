import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAiToolSuggestion } from 'src/app/models/ai-tool.model';
import { faCommentDots, faLink, faTag } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EToolStatus } from 'src/app/enum/ai-tool.enum';
import { IConfirmData } from 'src/app/models/confirm-data';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AiToolService } from 'src/app/services/aiTool.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-ai-tool-suggestion-card',
  templateUrl: './ai-tool-suggestion-card.component.html',
  styleUrls: ['./ai-tool-suggestion-card.component.scss'],
})
export class AiToolSuggestionCardComponent {
  @Input() tool: IAiToolSuggestion;
  @Output() toolCardRemoved = new EventEmitter<IAiToolSuggestion>();
  public badgeIcon = faTag;

  public confirmDialog = false;
  private subscription = new Subscription();
  public modalData = {} as IConfirmData;
  public commentIcon = faCommentDots;
  public faLink = faLink;
  public EToolStatus = EToolStatus;

  constructor(
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private aiToolService: AiToolService,
    public helperService: HelperService,
  ) {}

  public createRemoveModal(): void {
    this.confirmDialog = true;
    this.modalData.headline = `Delete Tool Card?`;
    this.modalData.text = `Are you sure yout want to delete this card permanently?`;
    this.modalData.image = 'delete';
  }

  public deleteCard(): void {
    this.subscription.add(
      this.aiToolService.deleteToolSuggestion(this.tool._id).subscribe({
        next: () => {
          this.toolCardRemoved.emit(this.tool as IAiToolSuggestion);
          this.toastr.success('Tool was deleted successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public assignToMe() {
    const assigneeId = this.authService.getCurrentEmployee()._id;
    this.subscription.add(
      this.aiToolService.assignToolSuggestion(this.tool._id, assigneeId).subscribe({
        next: (res) => {
          this.tool.assignee = res.DATA.assignee;
          this.toastr.success('Tool suggestion assigned successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public unassign(): void {
    this.subscription.add(
      this.aiToolService.unassignToolSuggestion(this.tool._id).subscribe({
        next: () => {
          this.tool.assignee = null;
          this.toastr.success('Tool suggestion unassigned successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
