import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IAiTool, IAiToolSuggestion } from 'src/app/models/ai-tool.model';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { faCommentDots, faLink, faTag } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AiToolService } from 'src/app/services/aiTool.service';
import { HelperService } from 'src/app/services/helper.service';
import { EToolStatus } from 'src/app/enum/ai-tool.enum';

@Component({
  selector: 'app-ai-tool-review-card',

  templateUrl: './ai-tool-review-card.component.html',
  styleUrl: './ai-tool-review-card.component.scss',
})
export class AiToolCardComponent {
  @ViewChild('commentContainer') private commentContainer!: ElementRef;
  @Input() tool: IAiTool;
  @Output() toolCardRemoved = new EventEmitter<IAiTool>();
  @Output() onEditTool = new EventEmitter<IAiTool>();
  public badgeIcon = faTag;

  public newComment = '';
  public showComments = false;
  public confirmDialog = false;
  private subscription = new Subscription();
  public modalData = {} as IConfirmData;
  public commentIcon = faCommentDots;
  public faLink = faLink;
  public EToolStatus = EToolStatus;
  public showMore = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private aiToolService: AiToolService,
    public helperService: HelperService,
  ) {}

  public onEditClick(): void {
    this.onEditTool.emit(this.tool);
  }

  public closeToolCard(): void {
    this.subscription.add(
      this.aiToolService.closeToolCard(this.tool._id).subscribe({
        next: () => {
          this.toolCardRemoved.emit(this.tool as IAiTool);
          this.toastr.success('Tool card was closed successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public onKeyUp(event: KeyboardEvent, isAdvantageField = false): void {
    if (event.key !== 'Enter') return;
    this.onCreateComment();
  }

  public voteTool(tool: IAiTool, voteType: 'up' | 'down'): void {
    const employeeId = this.authService.getCurrentEmployee()?._id;

    const userHasVoted = (this.tool as IAiTool).hasVoted.includes(employeeId);
    if (userHasVoted) {
      this.toastr.error('You have already voted');
      return;
    }
    const newCount = voteType === 'up' ? tool.voteCount + 1 : tool.voteCount - 1;
    this.subscription.add(
      this.aiToolService.voteTool(this.tool._id, newCount).subscribe({
        next: () => {
          (this.tool as IAiTool).voteCount = newCount;
          this.toastr.success('Tool was successfully voted');
        },
        error: () => {
          this.toastr.error('Failed to vote on tool');
        },
      }),
    );
  }

  public deleteToolCard(): void {
    this.subscription.add(
      this.aiToolService.deleteToolReview(this.tool._id).subscribe({
        next: () => {
          this.toolCardRemoved.emit(this.tool as IAiTool);
          this.toastr.success('Tool was deleted successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public toggleComments(): void {
    this.showComments = !this.showComments;
    if (this.showComments) {
      setTimeout(() => {
        this.scrollToBottom();
      });
    }
  }

  private scrollToBottom(): void {
    const container = this.commentContainer.nativeElement;
    container.scrollTop = container.scrollHeight;
  }

  public onDelteModalConfirmation(): void {
    if (this.tool.status === EToolStatus.CLOSED) {
      this.deleteToolCard();
    } else {
      this.closeToolCard();
    }
  }

  public onCreateComment(): void {
    this.subscription.add(
      this.aiToolService.createComment(this.tool._id, this.newComment).subscribe({
        next: (res) => {
          (this.tool as IAiTool).comments = res.DATA.comments;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
        complete: () => {
          this.newComment = '';
        },
      }),
    );
  }

  public deleteComment(commentId: string): void {
    this.subscription.add(
      this.aiToolService.deleteComment(this.tool._id, commentId).subscribe({
        next: () => {
          this.tool.comments = this.tool.comments.filter((comment) => comment._id !== commentId);
          this.toastr.success('Comment deleted successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public createRemoveModal(): void {
    if (this.tool.status === EToolStatus.CLOSED) {
      this.confirmDialog = true;
      this.modalData.headline = `Delete Tool Card?`;
      this.modalData.text = `Are you sure yout want to delete this card permanently?`;
      this.modalData.image = 'delete';
    } else {
      this.confirmDialog = true;
      this.modalData.headline = `Close Tool Card`;
      this.modalData.text = `Are you sure you want to set this card to closed?`;
      this.modalData.image = 'feedback';
    }
  }

  public showCloseButton(): boolean {
    return this.authService.getCurrentEmployee().isAdmin && !this.router.url.includes('/dashboard');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
