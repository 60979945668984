import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EToolStatus } from 'src/app/enum/ai-tool.enum';
import { ISortOption } from 'src/app/models/sort.model';

@Component({
  selector: 'app-filter-segment',
  templateUrl: './filter-segment.component.html',
  styleUrl: './filter-segment.component.scss',
})
export class FilterSegmentComponent {
  @Input() selectedOption: ISortOption = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
    filterBy: EToolStatus.ALL,
  };

  @Output() sortOptionSelected = new EventEmitter<ISortOption>();

  public EToolStatus = EToolStatus;

  public selectOption(option: string): void {
    this.selectedOption.filterBy = option as EToolStatus;
    this.sortOptionSelected.emit(this.selectedOption);
  }
}
