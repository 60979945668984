<div data-cy="suggestion-tool-card" class="relative flex flex-col">
  <div class="center mb-1 flex w-full flex-col items-center pt-4">
    @if (authService.getCurrentEmployee().isAdmin) {
      <button data-cy="tool-card-remove-button" (click)="createRemoveModal()" class="z-10 -mb-3 flex bg-lightGrayish">
        <img src="../../../assets/img/icons/delete.png" class="h-6" alt="delete icon" />
      </button>
    }

    @if (!tool.assignee) {
      <button
        data-cy="assign-to-me-button"
        (click)="assignToMe()"
        class="absolute right-2 top-10 z-20 flex items-center justify-center border border-white bg-sunflowerGold p-1 text-[10px] text-white"
      >
        Assign to Me
      </button>
    } @else if (tool.assignee._id === authService.getCurrentEmployee()._id) {
      <button
        data-cy="unassign-button"
        (click)="unassign()"
        class="absolute right-2 top-10 z-20 flex items-center justify-center border border-white bg-sunflowerGold p-1 text-[10px] text-white"
      >
        Unassign
      </button>
    }
    <h3 data-cy="suggestion-title" class="absolute left-6 top-12 z-20 text-sm font-semibold">{{ tool.toolName }}</h3>

    <div
      data-cy="tool-suggestion-card"
      class="relative flex w-full flex-col border-2 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 max-lg:text-xs lg:p-5"
    >
      <div class="mt-8 flex flex-col gap-4">
        <div class="flex flex-1 flex-col gap-1">
          <span data-cy="suggestion-description-label" class="text-xs font-semibold">Description</span>
          <span data-cy="suggestion-description" class="text-xs">{{ tool.description }}</span>
        </div>
        <div class="flex justify-between">
          <div class="flex flex-1 flex-col gap-1">
            <span data-cy="assignee-label" class="text-xs font-semibold">Assignee</span>
            <span data-cy="assignee" class="text-xs">{{
              tool.assignee ? tool.assignee.firstname + ' ' + tool.assignee.lastname : 'Unassigned'
            }}</span>
          </div>
          <div class="flex flex-1 flex-col gap-1">
            <span data-cy="website-label" class="text-xs font-semibold">Website</span>
            <a
              data-cy="website"
              href="{{ tool.website }}"
              target="_blank"
              class="max-w-[8rem] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-xs underline"
            >
              {{ tool.website }}
            </a>
          </div>
        </div>
      </div>
    </div>

    @if (confirmDialog) {
      <app-confirm-dialog
        data-cy="confirm-dialog"
        (closeDialog)="confirmDialog = false"
        (saveChanges)="deleteCard()"
        [data]="modalData"
      ></app-confirm-dialog>
    }
  </div>
</div>
