import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { EToolStatus } from 'src/app/enum/ai-tool.enum';
import { IAiTool, IAiToolSuggestion } from 'src/app/models/ai-tool.model';

import { ISortOption } from 'src/app/models/sort.model';
import { AiToolService } from 'src/app/services/aiTool.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-ai-tool-list',
  templateUrl: './ai-tool-list.component.html',
  styleUrls: ['./ai-tool-list.component.scss'],
})
export class AiToolListComponent {
  @Input() isToolSuggestionList = false;

  private subscription = new Subscription();
  public filterOptions: ISortOption = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
    searchParam: '',
    filterBy: EToolStatus.TESTED,
  };

  public selectedTool: IAiTool;
  public showAiToolEditDialog = false;

  public confirmDialog = false;

  public toolReviews: IAiTool[] = [];
  public toolSuggestions: IAiToolSuggestion[] = [];
  public EToolStatus = EToolStatus;
  public storageKey = 'toolReviewFilters';
  private searchSubject = new Subject<void>();
  public searchHovered = false;

  public currentReviewPage: number = 1;
  public totalCount: number = 1;
  public itemsPerPage: number = 10;

  constructor(
    private aiToolService: AiToolService,
    private toastr: ToastrService,
    public router: Router,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.storageKey = this.isToolSuggestionList ? 'toolSuggestionFilters' : 'toolReviewFilters';
    this.filterOptions.filterBy = this.isToolSuggestionList ? EToolStatus.ALL : EToolStatus.TESTED;
    this.subscription.add(
      this.searchSubject.pipe(debounceTime(700)).subscribe(() => {
        this.searchTools();
      }),
    );
    this.getFiltersFromLocalStorage();
    this.getAllTools();
  }

  public onSearch() {
    this.searchSubject.next();
  }

  public onOpenAiToolDialog(tool: IAiTool): void {
    if (tool) {
      this.selectedTool = tool;
    } else {
      this.selectedTool = null;
    }
    this.showAiToolEditDialog = true;
  }

  public updateToolReview(tool: IAiTool): void {
    this.subscription.add(
      this.aiToolService.updateToolReview(tool).subscribe({
        next: () => {
          this.getAllTools();
          this.showAiToolEditDialog = false;
          this.toastr.success('Tool was successfully updated');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public searchTools(): void {
    localStorage.setItem(this.storageKey, JSON.stringify(this.filterOptions));

    this.getAllTools();
  }

  private getFiltersFromLocalStorage(): void {
    const filters = localStorage.getItem(this.storageKey);
    if (filters) {
      this.filterOptions = JSON.parse(filters);
    }
  }

  public resetSearch(): void {
    this.filterOptions.searchParam = '';
    localStorage.setItem(this.storageKey, JSON.stringify(this.filterOptions));
    this.getAllTools();
  }

  private getAllTools(): void {
    if (this.isToolSuggestionList) {
      this.getAllToolSuggestions();
    } else {
      this.getAllToolReviews();
    }
  }

  public onPageChange(page: number): void {
    this.currentReviewPage = page;
    this.getAllTools();
  }

  public sortOptionSelected(sortOption: ISortOption): void {
    this.filterOptions.sortBy = sortOption.sortBy;
    this.filterOptions.sortOrder = sortOption.sortOrder;

    localStorage.setItem(this.storageKey, JSON.stringify(this.filterOptions));
    this.currentReviewPage = 1;
    this.getAllTools();
  }

  public filterOptionSelected(filterOptions: ISortOption): void {
    this.filterOptions.filterBy = filterOptions.filterBy as EToolStatus;

    localStorage.setItem(this.storageKey, JSON.stringify(this.filterOptions));

    this.getAllToolSuggestions();
  }

  public getAllToolReviews(): void {
    this.filterOptions.currentPage = this.currentReviewPage;
    this.subscription.add(
      this.aiToolService.getAllToolReviews(this.filterOptions, this.currentReviewPage, this.itemsPerPage).subscribe({
        next: (res: any): void => {
          this.toolReviews = res.DATA.data; // Update the tools list
          this.totalCount = res.DATA.totalCount; // Store total count for pagination
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }
  public getAllToolSuggestions(): void {
    this.subscription.add(
      this.aiToolService.getAllToolSuggestions(this.filterOptions.filterBy).subscribe({
        next: (res: any): void => {
          this.toolSuggestions = res.DATA;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public toggleClosedTools(): void {
    if (this.filterOptions.filterBy === EToolStatus.CLOSED) {
      this.filterOptions.filterBy = EToolStatus.TESTED;
    } else {
      this.filterOptions.filterBy = EToolStatus.CLOSED;
    }
    localStorage.setItem(this.storageKey, JSON.stringify(this.filterOptions));
    this.getAllTools();
  }

  public onToolSuggestionRemoved(tool: IAiToolSuggestion): void {
    this.toolSuggestions = this.toolSuggestions.filter((f) => f._id !== tool._id);
  }
  public onToolReviewRemoved(tool: IAiTool): void {
    this.toolReviews = this.toolReviews.filter((f) => f._id !== tool._id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
